/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-08",
    versionChannel: "nightly",
    buildDate: "2024-02-08T00:15:04.388Z",
    commitHash: "ec403e614dbe1ea265e7e0c2f5f02d41b483582b",
};
